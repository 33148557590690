<template>
    <v-app id="bg-app">
        
        <img src="/img/Group-13.png" class="img-top" width="100%">
        <img src="/img/oie_8sdXYoVrBvrb.png" alt="" srcset="" class="center-img">
        <img src="/img/Group-17.png" alt="" srcset="" class="img-panah">
        
        <b-button
            pill
            to="product-overviews-02"
            size="sm"
            class="btn_show_product">                                            
            <span class="text_btn">
                UNBOX NOW
            </span>
        </b-button>

        <img src="/img/Group-14.png" class="bg-buttom" width="100%">
    </v-app>
</template>

<script>
export default {
  name: 'SubmitQuestion',
  methods: {
    changeColor(){
      var metaThemeColor = document.querySelector("meta[name=theme-color]");
          metaThemeColor.setAttribute("content", "#F37679");
    }
  },
  created() {
      this.changeColor();
  },
}
</script>


<style lang="stylus">
    @font-face {
        font-family: Caveat;
        src: url('/fonts/Caveat.ttf');
    }
    @font-face {
        font-family: CaveatBrush;
        src: url('/fonts/CaveatBrush.ttf');
    }
    .img-top{
        position : fixed;
        top: -65px;
    }

    .text-wrap{
        font-family : Caveat;
        z-index : 99;
        width : 300px;
        margin : 0 auto;
        text-align :center;
        font-size: 26px;
        color : #fff;
        line-height : 28px;
        margin-top : -505px;
        font-weight: bold;
        font-style: normal;
    }
    #bg-app {
        width: 100%;
        height: 100%;
        position : fixed;
        z-index : 87;
        padding-top : 120px;
    }
    .bg-buttom{
        position :absolute;
        bottom : 0;
        z-index : 87;
        position : fixed;
        bottom : -70px;
    }
    .center-img{
        width : 300px;
        text-align :center;
        margin: 0 auto;
        margin-top : 50px;
        z-index :99;
    }
    .img-panah{
        width : 270px;
        text-align :center;
        margin: 0 auto;
        margin-top : -20px;
        padding-right : 30px;
        z-index :99;
    }
    
    .btn_show_product{
        z-index:99999999;
        width: 120px;
        background: #313D8E !important;
        border: 0px solid #F37679;
        text-align :center;
        margin: 0 auto; 
        margin-top:-30px;
        padding-top : 10px;
        padding-bottom : 10px;
    }
    .text_btn{
        font-size : 15px;
        line-height : 16px;
        color : #FFBD12;
    }
</style>